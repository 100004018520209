import { createActionGroup, props } from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from '../calendar.state';
import { CalendarMode } from '@features/calendar/business';
import {
  AggregationTimespan,
  ContributorScheduleDto,
  WorkingSummaryDto,
} from '@swagger/generated/humanresources';

const ACTION_PREFIX = `${FEATURE_ACTION_PREFIX} - Plan`;
export const planActions = createActionGroup({
  source: `${ACTION_PREFIX} Plan Actions`,
  events: {
    Request: props<{ date: Date; mode: CalendarMode }>(),
    Success: props<{ response: ContributorScheduleDto }>(),
    Failure: props<{ error: any }>(),
  },
});

export const workingSummaryActions = createActionGroup({
  source: `${ACTION_PREFIX} Working Summary Actions`,
  events: {
    Request: props<{
      scheduleUId: string;
      date: Date;
      mode: CalendarMode;
      aggregatedBy: AggregationTimespan;
    }>(),
    Success: props<{ workingSummaryValues: WorkingSummaryDto[] }>(),
    Failure: props<{ error: any }>(),
  },
});

export const workingSummaryMonthActions = createActionGroup({
  source: `${ACTION_PREFIX} Working Summary Month Actions`,
  events: {
    Request: props<{
      scheduleUId: string;
      date: Date;
    }>(),
    Success: props<{ workingSummaryMonthValues: WorkingSummaryDto }>(),
    Failure: props<{ error: any }>(),
  },
});
